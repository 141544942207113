import React from "react";

import { SwiperCarousel } from "supercell-frontend-base/src/components/swiper-carousel/swiper-carousel";
import { CarouselSlide } from "supercell-frontend-base/src/components/swiper-carousel/slide";
import { Grid } from "supercell-frontend-base/src/components/grid";
import { List } from "supercell-frontend-base/src/components/list";
import { ListArticle } from "supercell-frontend-base/src/components/list/article";
import { ContentfulSocialLinks } from "supercell-frontend-base/src/components/contentful-social-links";
import { Tab } from "supercell-frontend-base/src/components/inbox/tab";

import { inboxParser } from "supercell-frontend-base/src/parsers/inbox";

import { filterEntry } from "supercell-frontend-base/src/targeting/targeting";
import { ClashRoyaleGameParams } from "validation/game-params";
import { useGameParamsContext } from "supercell-frontend-base/src/stores/gameParams";

import styles from "./styles.modules.scss";

export const NewsView = () => {
    return (
        <Tab namespace="news" parser={inboxParser}>
            {({ locale, data }) => {
                const { gameParams } =
                    useGameParamsContext<ClashRoyaleGameParams>();

                return (
                    <div
                        className={`news ${styles.news} ${
                            locale.rtl ? `rtl ${styles.rtl}` : ""
                        }`}
                    >
                        <Grid>
                            {data.events && data.events["length"] > 0 && (
                                <div className={`events ${styles.events}`}>
                                    <SwiperCarousel
                                        locale={locale}
                                        slides={data.events.filter((e) =>
                                            filterEntry(e, gameParams)
                                        )}
                                        Slide={CarouselSlide}
                                    />
                                </div>
                            )}

                            {data.articles && (
                                <div className={`articles ${styles.articles}`}>
                                    <List
                                        Item={ListArticle}
                                        items={data.articles.filter((e) =>
                                            filterEntry(e, gameParams)
                                        )}
                                        footer={
                                            <ContentfulSocialLinks
                                                links={data.socialLinks}
                                            />
                                        }
                                    />
                                </div>
                            )}
                        </Grid>
                    </div>
                );
            }}
        </Tab>
    );
};
