import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useSendCommand } from "supercell-frontend-base/src/hooks/use-send-command";
import { useSnowplowContext } from "supercell-frontend-base/src/analytics/snowplow";

import { TRACK_EVENT } from "supercell-frontend-base/src/constants/types";

import { NewsView } from "./view";

export const NewsController = () => {
    const { pathname: pagePath } = useLocation();
    const sendCommand = useSendCommand();
    const { pageView } = useSnowplowContext();

    useEffect(() => {
        pageView("News Frontpage");
        sendCommand(TRACK_EVENT, {
            event: "Pageview",
            pagePath,
            pageTitle: "News Frontpage",
        });

        window.history.pushState({ home: true }, undefined);
    }, []);

    return <NewsView />;
};
